<template>
  <div class="container-fluid">

    <div class="card search-card">
      <div class="card-body">
        <div class="form-row" style="justify-content: end;text-align: end;">
          <!-- <div class="col-sm-3 col-md-2">
            <div class="form-group">
              <label>{{ i18n("Role.RoleName") }}</label>
              <input
                type="text"
                class="form-control"
                placeholder="請輸入關鍵字"
                @keydown.stop.prevent.enter="getRole"
                v-model.trim="roleName"
              />
            </div>
          </div> -->

          <div class="col-sm-3 col-md-3">
            <!-- <button
              type="button"
              class="btn btn-primary btn-search"
              style="margin-top: 30px; margin-left: 10px"
              @click="getRole"
            >
              {{ i18n("Buttons.Search") }}
            </button> -->
            <button
              type="button"
              class="btn btn-primary btn-create"
              data-toggle="modal"
              data-target="#footprintModal"
              @click="footprintModal(true)"
            >
              新增足跡
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="yahome-table-wrap">
      <VueBootstrap4Table :rows="rows" :columns="columns" :config="config" :classes="classes" @on-change-query="onChangeQuery" :total-rows="total">

        <template slot="name" slot-scope="props">
          <span :title="props.row.English">{{ props.row.Chinese }}</span>
        </template>

        <template slot="edit" slot-scope="props">
          <button
            class="btn btn-info btn-sm"
            style="margin-right: 0.5rem"
            data-toggle="modal"
            data-target="#footprintModal"
            @click="footprintModal(false, props.row)"
          >
            {{ i18n("Buttons.Edit") }}
          </button>
        </template>

        <template slot="delete" slot-scope="props">
          <button
            class="btn btn-danger btn-delete btn-sm"
            data-toggle="modal"
            data-target="#delFootprintModal"
            @click="footprintModal(false, props.row)"
          >
            {{ i18n("Buttons.Delete") }}
          </button>
        </template>
        <template slot="empty-results"> 暫無資料 </template>
      </VueBootstrap4Table>
    </div>

    <!-- 新增&編輯足跡 Modal -->
    <div
      id="footprintModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <ValidationObserver
          ref="footprint"
          class="modal-content"
          v-slot="{ handleSubmit, reset }"
        >
          <form @reset.prevent="reset">
            <div class="modal-header">
              <span v-if="isAddFootprint">新增足跡</span>
              <span v-else>編輯足跡</span>
            </div>
            <div class="modal-body">
              <!-- <div class="field">
                  <ValidationProvider
                    tag="div"
                    :name="i18n('Role.RoleName')"
                    :rules="`my_not:${nowItem.Name}|required`"
                    v-slot="{ errors }"
                  >
                    <label>
                      <span class="mark">*</span>足跡時間
                    </label>
                    <input
                      type="text"
                      class="form-control"
                    />
                    <span v-if="errors[0] && !is_composing" class="text-danger">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div> -->

              <div class="field">
                <ValidationProvider
                  tag="div"
                  :name="i18n('Role.RoleName')"
                  :rules="`my_not:${nowItem.Name}|required`"
                  v-slot="{ errors }"
                >
                  <label>
                    <span class="mark">*</span>足跡名稱
                    <!-- <span class="mark">(不可重複)</span> -->
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="item.Chinese"
                  />
                  <span v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>

              <div class="field">
                <ValidationProvider
                  tag="div"
                  :name="i18n('Role.RoleName')"
                  :rules="`my_not:${nowItem.Name}|required`"
                  v-slot="{ errors }"
                >
                  <label>
                    <span class="mark">*</span>足跡英文名稱
                  </label>
                  <textarea class="form-control" cols="30" rows="5" v-model="item.English"></textarea>
                  <span v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="reset"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {{ i18n("Buttons.Cancel") }}
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="handleSubmit(saveFootprint)"
              >
                {{ i18n("Buttons.Save") }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>


    <!-- 刪除足跡 Modal -->
    <div
      id="delFootprintModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">刪除足跡</div>
          <div class="modal-body">
            確認刪除
            <span style="font-weight: bolder; color: red">
              {{ item.Name }} </span
            >?
          </div>
          <div class="modal-footer">
            <button type="reset" class="btn btn-secondary" data-dismiss="modal">
              {{ i18n("Buttons.Cancel") }}
            </button>
            <button type="button" class="btn btn-danger" @click="delFootprint">
              {{ i18n("Buttons.Delete") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global $ */
import { config } from "@/components/table_config.js";
import { mapGetters, mapActions } from "vuex";
import { apiGetFootprintPages, apiAddFootprint, apiEditFootprint } from '@/apis/footprint'


export default {

  data() {
    return {

      // roleName: null,
      // 角色的Modal狀態
      isAddFootprint: false,

      // 判斷角色名稱是否重複
      item: {},
      nowItem: {},

      // currentRole: "",

      rows: [],
      columns: [
        {
          label: "足跡名稱",
        //   name: "Name",
          slot_name: "name",
        },
        {
          label: "足跡名稱(英文)",
          name: "English",
        },
        {
          label: "",
          slot_name: "edit",
        },
        {
          label: "",
          slot_name: "delete",
        },
      ],
      config: {
          ...config,
          server_mode: true,
      },
      classes: {
          tableWrapper: "table-responsive footprint-table",
          cell: {
              "text-left" : true
          },
      },
      tableInfo: {
          // 分頁資訊
          page: 1,
          per_page: 10,
      },
      total: 0,
    };
  },

  computed: {
    ...mapGetters(["lang"]),
  },

  watch: {
    lang: function() {
    //   this.columns = [
    //     { label: this.i18n("Role.RoleName"), name: "Name" },
    //     {
    //       label: this.i18n("Role.Permission"),
    //       name: "setMenu",
    //       slot_name: "setMenu",
    //     },
    //     {
    //       label: this.i18n("Basic.Employee"),
    //       name: "setUser",
    //       slot_name: "setUser",
    //     },
    //     { label: "", slot_name: "edit" },
    //     { label: "", slot_name: "delete" },
    //   ];
    },

  },

  methods: {
    ...mapActions(['updateLoading']),
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },

    // 角色 新增&編輯&刪除 Modal
    footprintModal(isAdd, item) {
      if (!isAdd) {
        this.item = { ...item };
        // 原角色 data
        this.nowItem = {
          ...item,
        };
        this.isAddFootprint = false;
      } else {
        this.item = {};
        this.isAddFootprint = true;
      }
      this.$refs.footprint.reset();
    },

    onChangeQuery(queryParams) {
        const vm = this;
        console.log('queryParams', queryParams)
        // console.log(JSON.parse(JSON.stringify(queryParams)));

        vm.tableInfo = {
            page: queryParams.page,
            per_page: queryParams.per_page,
        };

        vm.getFootprintPages(
            vm.tableInfo.page,
            vm.tableInfo.per_page,
        );
    },

    //  查詢足跡
    getFootprintPages(page = 1, per_page = 10) {

      this.updateLoading(true)
      apiGetFootprintPages({
          Page: page,
          PerPage: per_page,
      }).then((response) => {
          console.log(response)

          if(response.status === 200) {
            this.rows = response.data.Dtos
            this.total = response.data.DataQuantity
          }

          this.updateLoading(false)
      })
    },

    //  新增足跡
    addFootprint() {

      this.updateLoading(true)
      apiAddFootprint({
        ...this.item
      }).then((response) => {
          console.log(response)

          if(response.status === 200) {
            this.item = {};
            $("#footprintModal").modal("hide");

            this.getFootprintPages(
                this.tableInfo.page,
                this.tableInfo.per_page,
            );

            this.$notify({
                title: "成功",
                message: "成功新增足跡",
                type: "success",
                duration: 3500,
            });
          }

          this.updateLoading(false)
      })
    },

    //  編輯足跡
    editFootprint() {

      this.updateLoading(true)
      apiEditFootprint({
        ...this.item
      }).then((response) => {
          console.log(response)

          if(response.status === 200) {
            this.item = {};
            $("#footprintModal").modal("hide");

            this.getFootprintPages(
                this.tableInfo.page,
                this.tableInfo.per_page,
            );

            this.$notify({
                title: "成功",
                message: "成功編輯足跡",
                type: "success",
                duration: 3500,
            });
          }

          this.updateLoading(false)
      })
    },

    saveFootprint() {
      if(this.isAddFootprint) {
        this.addFootprint()
      } else {
        this.editFootprint()
      }
    },

    //  刪除足跡
    delFootprint() {
      const vm = this;
      const api = `${window.BaseUrl.api}/Questionnire/DeleteFootprint/${vm.item.ID}`;

      this.updateLoading(true)
      
      vm.$http.delete(api).then((response) => {
          console.log(response)

          if(response.status === 200) {
            this.item = {};
            $("#delFootprintModal").modal("hide");

            this.getFootprintPages(
                this.tableInfo.page,
                this.tableInfo.per_page,
            );

            this.$notify({
                title: "成功",
                message: "成功刪除足跡",
                type: "success",
                duration: 3500,
            });
          }

          this.updateLoading(false)
      })
      .catch(() => {
        this.updateLoading(false)
      })
    },
  },

  mounted() {
    this.getFootprintPages()
  },

  destroyed() {
    $(".modal").modal("hide");
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
    this.$notify.close();
  },
};
</script>

<style lang="scss" scoped>
.menu-select-list {
  overflow: auto;
  height: 100%;
  & > div {
    background-color: #f1f1f1;
    border-radius: 5px;
    margin-bottom: 5px;

    .menu-name {
      padding: 10px;
      cursor: pointer;
      opacity: 0.5;
      display: flex;
      span {
        flex: 1;
      }
      i {
        transition: all 0.2s;
      }
    }
    .option-wrap {
      max-height: 0px;
      overflow: hidden;
      padding: 0 10px;
      transition: all 0.2s;

      .option {
        background-color: #fff;
        text-align: center;
        margin-bottom: 10px;
        border-radius: 5px;
        padding: 5px 0;
        display: block;
        cursor: pointer;
        font-size: 0.9em;
        transition: all 0.2s;

        &.active {
          background-color: #5380f7;
          color: #fff;
        }
        &.hasPermission {
          background-color: #e3e7f1;
          color: #1d4ccc;
          font-weight: bold;
        }
        &.hascheck {
          &:before {
            content: "✔";
          }
        }
      }
    }

    &.open {
      .menu-name {
        i {
          transform: rotate(90deg);
        }
      }
      .option-wrap {
        max-height: 1000px;
      }
    }
  }
}
.permission-select-list {
  label {
    display: block;
    background-color: #f1f1f1;
    margin-bottom: 5px;
    padding: 10px;
    display: flex;
    cursor: pointer;

    input[type="checkbox"] {
      display: none;
      &:checked {
        & ~ .switch {
          span {
            background-color: #5380f7;
            transform: translateX(calc(100% - 6px));
          }
        }
      }
    }
    & > span {
      flex: 1;
    }

    .switch {
      display: inline-block;
      vertical-align: middle;
      padding: 3px;
      border: 3px solid #c5c5c5;
      background-color: #fff;
      border-radius: 25px;
      width: 60px;
      position: relative;
      height: 29px;
      span {
        width: 50%;
        height: calc(100% - 6px);
        position: absolute;
        left: 3px;
        top: 3px;
        background-color: #c5c5c5;
        border-radius: 25px;
        transition: all 0.3s;
      }
    }
  }
}
</style>
